<template>
  <!-- <center v-show="userDataInfo != null" style="font-size: 1.3em;" class="mt-2">
        Hello <strong>{{ userDataInfo ? userDataInfo.name : 'N/A' }}</strong
        >, welcome to <strong>{{ userDataInfo ? userDataInfo.company.name : 'N/A' }}</strong
        >'s Client Portal.
      </center> -->
  <div>
    <!-- metrics -->
    <b-card title="Metrics" class="mt-2" style="margin-bottom:10rem">
      <validation-observer ref="metricsRules">
        <b-form>
          <b-row>
            <!-- Title Codename -->
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Title"
                style="width: 100%;"
                rules="required"
              >
                <b-form-group label-for="basicInput">
                  <b-form-input
                    id="basicInput"
                    :trim="true"
                    v-model="formulData.codeName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                    ref="formulTitle"
                    @change="checkFormulTitle()"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Alias -->
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Alias"
                style="width: 100%;"
                rules="required"
              >
                <b-form-group label-for="basicInput">
                  <b-form-input
                    id="basicInput"
                    v-model="formulData.alias"
                    :trim="true"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Alias"
                    ref="formulTitle"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <hr />
          <b-row
            class="mt-1"
            style="height: 50vh;overflow-y:auto;overflow-x: auto;"
          >
            <b-col
              md="6"
              xl="3"
              class="mb-1"
              v-for="(operation, index) in operations"
              :key="index"
              v-if="!ifElseConditional"
            >
              <b-form-group>
                <!-- select type -->
                <validation-provider
                  #default="{ errors }"
                  name="Metric"
                  style="width: 100%;"
                >
                  <v-select
                    class="animate__animated animate__fadeIn"
                    v-if="operation.selected == 0"
                    v-model="operation.type"
                    label="title"
                    placeholder="Select Metrics Type"
                    :state="errors.length > 0 ? false : null"
                    @option:selected="selectedOperation(operation, index)"
                    @open="openInputSelectTypes(operation, index)"
                    :selectable="(option) => option.disabled"
                    :clearable="false"
                    :options="option"
                    ref="dropdown"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- input -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Input'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Input"
                    rules="required"
                    style="width: 100%;"
                  >
                    <!-- <b-form-input
                        class="animate__animated animate__fadeIn"
                        v-model="operation.type"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        id="basicInput"
                        placeholder="Input"
                        :disabled="true"
                      /> -->
                    <v-select
                      v-model="operation.value"
                      :reduce="(val) => val"
                      placeholder="Select Input Type"
                      :clearable="false"
                      :options="inputOptions"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="
                        selectedInputOperation(operation, index)
                      "
                      :disabled="operation.disabled"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="doubleclick(operation, index)">
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextInput(operation, index)"
                      :disabled="operations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="operations.length == 1"
                      @click="removetInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- numeric -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Numeric'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="numeric"
                    rules="required|positive"
                    style="width: 100%;"
                  >
                    <b-form-input
                      class="animate__animated animate__fadeIn"
                      v-model.number="operation.value"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      id="basicInput"
                      placeholder="Numeric"
                      :disabled="operation.disabled"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="doubleclick(operation, index)">
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextInput(operation, index)"
                      :disabled="operations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="operations.length == 1"
                      @click="removetInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Math operators -->
                <div
                  v-if="
                    operation.selected == 1 &&
                      operation.type == 'Math Operators'
                  "
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Math Operators"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      label="title"
                      :reduce="(val) => val"
                      :clearable="false"
                      placeholder="Select Math Operator"
                      :options="mathOptions"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="selectedMathOperation(operation, index)"
                      :disabled="operation.disabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="doubleclick(operation, index)">
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextInput(operation, index)"
                      :disabled="operations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="operations.length == 1"
                      @click="removetInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Formula -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Formula'"
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Formula"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      :options="formulOptions"
                      placeholder="Select Formula"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        selectedFormulOperation(operation, index)
                      "
                      label="alias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="doubleclick(operation, index)">
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextInput(operation, index)"
                      :disabled="operations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="operations.length == 1"
                      @click="removetInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Lookup Table select table -->
                <div
                  v-if="
                    operation.selected == 1 && operation.type == 'Lookup Table'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lookup Table"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      :options="lookupTableOptions"
                      placeholder="Select Lookup Table"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        selectedLookupOperation(operation, index)
                      "
                      label="table"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="doubleclick(operation, index)">
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Lookup Table select & result columns -->
                <div
                  v-if="
                    operation.selected == 2 && operation.type == 'Lookup Table'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn lookup-select-placeholder"
                >
                  <!-- select column -->
                  <validation-provider
                    #default="{ errors }"
                    name="Select Column"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="
                        'Selected table column: ' + operation.lookup.selectC
                      "
                      v-model="operation.lookup.selectC"
                      :options="operation.columns"
                      placeholder="Select Column"
                      :reduce="(val) => val.column"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @open="filterSelectedColumn(operation, index)"
                      @option:selected="
                        selectedLookupColumnsOperation(operation, index)
                      "
                      :selectable="(option) => option.disabled"
                      label="column"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>

                  <!-- result column -->
                  <validation-provider
                    #default="{ errors }"
                    name="Result column"
                    rules="required"
                    style="width: 100%;"
                    class="ml-1"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="
                        'Table column to result: ' + operation.lookup.resultC
                      "
                      v-model="operation.lookup.resultC"
                      :options="operation.columns"
                      placeholder="Result Columns"
                      :reduce="(val) => val.column"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @open="filterSelectedResultColumn(operation, index)"
                      @option:selected="
                        resultLookupColumnsOperation(operation, index)
                      "
                      :selectable="(option) => option.disabled"
                      label="column"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                </div>

                <!-- select lookup Input or Formula type -->
                <div
                  v-if="
                    operation.selected == 2 &&
                      operation.type == 'Lookup Operation'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn lookup-select-placeholder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lookup operations"
                    style="width: 100%;"
                  >
                    <v-select
                      class="animate__animated animate__fadeIn"
                      v-model="operation.type"
                      label="title"
                      placeholder="Select Lookup operation Type"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="
                        selectedMiddleLookupOperation(operation, index)
                      "
                      :clearable="false"
                      :options="lookupOption"
                      ref="dropdown"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <!-- Look up type formula -->
                <div
                  v-if="operation.selected == 2 && operation.type == 'Formula'"
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Formula"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="'Action in the table: ' + operation.type"
                      v-model="operation.value"
                      :options="formulOptions"
                      placeholder="Select Formula"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        selectedFormulOperation(operation, index)
                      "
                      label="alias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <!-- Lookup Not Found -->
                <div
                  v-if="
                    operation.selected == 2 &&
                      operation.type == 'Lookup Not Found'
                  "
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Not Found Variable"
                    rules="required|positive"
                    style="width: 100%;"
                  >
                    <b-form-input
                      v-b-tooltip.hover.v-dark
                      :title="'Result value when no value is found in table'"
                      class="animate__animated animate__fadeIn"
                      v-model.number="operation.value"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      id="basicInput"
                      placeholder="Can't Find"
                      :disabled="operation.disabled"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="doubleDefaultOprationClick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="2" md="2" xl="2" v-if="!ifElseConditional">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-dark"
                  class="btn-icon animate__animated animate__fadeIn"
                  @click="removeLastInput"
                  :disabled="operations.length == 1 || minusBtnDisabled"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-dark"
                  class="btn-icon ml-1"
                  @click="addNewInput"
                  :disabled="operations.length == 3"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-form-group>
            </b-col>

            <!-- If Else Conditional ----------------------------------------------->
            <b-col
              cols="12"
              v-if="ifElseConditional"
              class=" animate__animated animate__fadeIn mt-2"
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                class="input-dropdown"
                style="float: right;"
              >
                <template #button-content class="p-1">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="22"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="changeCalType()">
                  <feather-icon icon="RotateCcwIcon" />
                  <span class="align-middle ml-50">Change Input type</span>
                </b-dropdown-item>
              </b-dropdown>
              <b-row class="d-flex align-items-start justify-content-center">
                <!-- select first conditional type -->
                <b-col cols="3" class="mt-1 animate__animated animate__fadeIn">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Condition Metric"
                      style="width: 100%;"
                      v-if="conditionObj.firstSelect.type == null"
                      class="animate__animated animate__fadeIn"
                    >
                      <v-select
                        v-model="conditionObj.firstSelect.type"
                        label="title"
                        placeholder="First Conditional Type"
                        :state="errors.length > 0 ? false : null"
                        :selectable="(option) => option.disabled"
                        :clearable="false"
                        :options="conditionOption"
                        @option:selected="
                          selectedConditionOperation(conditionObj.firstSelect)
                        "
                        @open="openConditionInputSelectTypes('first')"
                        :reduce="(val) => val.title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <!-- numeric -->
                    <div
                      v-if="conditionObj.firstSelect.type == 'Numeric'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="first condition numeric"
                        style="width: 100%;"
                        rules="required|positive"
                      >
                        <b-form-input
                          class="animate__animated animate__fadeIn"
                          v-model.number="conditionObj.firstSelect.value"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          id="basicInput"
                          placeholder="Numeric"
                          @change="
                            selectedConditionNumericOperation(
                              conditionObj.firstSelect,
                              'first'
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item @click="doubleConditionclick('first')">
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <!-- formula -->
                    <div
                      v-if="conditionObj.firstSelect.type == 'Formula'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="first condition formula"
                        style="width: 100%;"
                        rules="required"
                      >
                        <v-select
                          v-model="conditionObj.firstSelect.value"
                          :options="formulOptions"
                          placeholder="Select Formula"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                          :clearable="false"
                          @option:selected="
                            selectedConditionFormulOperation(
                              conditionObj.firstSelect,
                              'first'
                            )
                          "
                          label="alias"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item @click="doubleConditionclick('first')">
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- select operation type -->
                <b-col cols="2" class="mt-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Condition operation"
                      style="width: 100%;"
                      rules="required"
                    >
                      <v-select
                        class="animate__animated animate__fadeIn"
                        v-model="conditionObj.conditionOperation"
                        placeholder="Select Condition Operation"
                        :state="errors.length > 0 ? false : null"
                        :clearable="false"
                        :options="conditionOperationOption"
                        :reduce="(val) => val"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- select second conditional type -->
                <b-col cols="3" class="mt-1 animate__animated animate__fadeIn">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="second condition metric"
                      style="width: 100%;"
                      v-if="conditionObj.secondSelect.type == null"
                      class="animate__animated animate__fadeIn"
                    >
                      <v-select
                        v-model="conditionObj.secondSelect.type"
                        label="title"
                        placeholder="Second Conditional Type"
                        :state="errors.length > 0 ? false : null"
                        :selectable="(option) => option.disabled"
                        :clearable="false"
                        :options="conditionOption"
                        @option:selected="
                          selectedConditionOperation(conditionObj.secondSelect)
                        "
                        @open="openConditionInputSelectTypes('second')"
                        :reduce="(val) => val.title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <!-- numeric -->
                    <div
                      v-if="conditionObj.secondSelect.type == 'Numeric'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="second condition numeric"
                        rules="required|positive"
                        style="width: 100%;"
                      >
                        <b-form-input
                          class="animate__animated animate__fadeIn"
                          v-model.number="conditionObj.secondSelect.value"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          id="basicInput"
                          placeholder="Numeric"
                          @change="
                            selectedConditionNumericOperation(
                              conditionObj.secondSelect,
                              'second'
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="doubleConditionclick('second')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <!-- formula -->
                    <div
                      v-if="conditionObj.secondSelect.type == 'Formula'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="second condition formula"
                        rules="required"
                        style="width: 100%;"
                      >
                        <v-select
                          v-model="conditionObj.secondSelect.value"
                          :options="formulOptions"
                          placeholder="Select Formula"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                          :clearable="false"
                          @option:selected="
                            selectedConditionFormulOperation(
                              conditionObj.secondSelect,
                              'second'
                            )
                          "
                          label="alias"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          @click="doubleConditionclick('second')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- if else section -->
              <b-row
                align-h="around"
                class=" mt-1 animate__animated animate__fadeIn"
                v-if="visibleIfElse"
              >
                <!-- if situation -->
                <b-col cols="3" md="3" xl="3" lg="3" sm="12">
                  <hr />
                  <b-card
                    border-variant="light"
                    header-text-variant="white"
                    header="If Yes"
                    class="text-center"
                    header-bg-variant="success"
                  >
                    <b-card-text class="mt-2">
                      <!-- select if type -->
                      <validation-provider
                        #default="{ errors }"
                        name="if condition metric"
                        style="width: 100%;"
                        v-if="conditionObj.ifSelect.type == null"
                        class="animate__animated animate__fadeIn mt-2"
                      >
                        <v-select
                          v-model="conditionObj.ifSelect.type"
                          label="title"
                          placeholder="If Conditional Type"
                          :state="errors.length > 0 ? false : null"
                          :selectable="(option) => option.disabled"
                          :clearable="false"
                          :options="conditionOption"
                          @option:selected="
                            selectedIfElseConditionOperation(
                              conditionObj.ifSelect
                            )
                          "
                          :reduce="(val) => val.title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <!-- if numeric -->
                      <div
                        v-if="conditionObj.ifSelect.type == 'Numeric'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="if condition numeric"
                          rules="required|positive"
                          style="width: 100%;"
                        >
                          <b-form-input
                            class="animate__animated animate__fadeIn"
                            v-model.number="conditionObj.ifSelect.value"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            id="basicInput"
                            placeholder="Numeric"
                            @change="
                              selectedIfElseConditionNumericOperation(
                                conditionObj.ifSelect,
                                'if'
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click="doubleIfElseConditionclick('if')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change If Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <!-- if formula -->
                      <div
                        v-if="conditionObj.ifSelect.type == 'Formula'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="if condition formula"
                          rules="required"
                          style="width: 100%;"
                        >
                          <v-select
                            v-model="conditionObj.ifSelect.value"
                            :options="formulOptions"
                            placeholder="Select Formula"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :clearable="false"
                            @option:selected="
                              selectedIfElseConditionFormulOperation(
                                conditionObj.ifSelect,
                                'if'
                              )
                            "
                            label="alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>

                          <b-dropdown-item
                            @click="doubleIfElseConditionclick('if')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change If Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>

                <!-- else situation --------------->
                <b-col cols="3" md="3" xl="3" lg="3" sm="12">
                  <hr />
                  <b-card
                    border-variant="light"
                    header-text-variant="white"
                    header="If No"
                    class="text-center"
                    header-bg-variant="danger"
                  >
                    <b-card-text class="mt-2">
                      <!-- select else type -->
                      <validation-provider
                        #default="{ errors }"
                        name="else condition metric"
                        style="width: 100%;"
                        v-if="conditionObj.elseSelect.type == null"
                        class="animate__animated animate__fadeIn mt-2"
                      >
                        <v-select
                          v-model="conditionObj.elseSelect.type"
                          label="title"
                          placeholder="Else Conditional Type"
                          :state="errors.length > 0 ? false : null"
                          :selectable="(option) => option.disabled"
                          :clearable="false"
                          :options="conditionOption"
                          @option:selected="
                            selectedIfElseConditionOperation(
                              conditionObj.elseSelect
                            )
                          "
                          :reduce="(val) => val.title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <!-- else numeric -->
                      <div
                        v-if="conditionObj.elseSelect.type == 'Numeric'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="else condition numeric"
                          rules="required|positive"
                          style="width: 100%;"
                        >
                          <b-form-input
                            class="animate__animated animate__fadeIn"
                            v-model.number="conditionObj.elseSelect.value"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            id="basicInput"
                            placeholder="Numeric"
                            @change="
                              selectedIfElseConditionNumericOperation(
                                conditionObj.elseSelect,
                                'else'
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click="doubleIfElseConditionclick('else')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change Else Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <!-- else formula -->
                      <div
                        v-if="conditionObj.elseSelect.type == 'Formula'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="else condition formula"
                          rules="required"
                          style="width: 100%;"
                        >
                          <v-select
                            v-model="conditionObj.elseSelect.value"
                            :options="formulOptions"
                            placeholder="Select Formula"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :clearable="false"
                            @option:selected="
                              selectedIfElseConditionFormulOperation(
                                conditionObj.elseSelect,
                                'else'
                              )
                            "
                            label="alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>

                          <b-dropdown-item
                            @click="doubleIfElseConditionclick('else')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change Else Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-row align-h="end">
            <!-- isOutput &  Intr Formula-->
            <b-col md="2" xl="2">
              <b-form-checkbox
                v-model="formulData.isOutput"
                class="custom-control-secondary checkbox-label"
              >
                See the Result
              </b-form-checkbox>
              <hr style="margin-top:0.3rem;margin-bottom:0.3rem" />
              <b-form-checkbox
                v-model="formulData.intrFormul"
                class="custom-control-secondary checkbox-label"
              >
                Intermediate Formula
              </b-form-checkbox>
            </b-col>
            <b-col
              md="12"
              xl="3"
              class="mb-1 animate__animated animate__fadeIn"
              v-if="!ifElseConditional"
            >
              <b-button
                @click="addFormula"
                variant="gradient-success"
                style="width: 100%;"
              >
                Add Formula
              </b-button>
            </b-col>
            <b-col
              md="12"
              xl="3"
              class="mb-1 animate__animated animate__fadeIn"
              v-if="ifElseConditional"
            >
              <b-button
                @click="conditionalAddFormula"
                variant="gradient-info"
                style="width: 100%;"
              >
                Add Conditional Formula
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- Tables -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card title="Tables" class="mt-2">
        <b-row>
          <b-table
            class="w-100"
            responsive="sm"
            :items="tableFormuls"
            :fields="formulTableHeaders"
            empty-text="No matching records found"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template #cell(id)="data">
              <strong class="text-primary"> #{{ data.item.id }} </strong>
            </template>
            <template #cell(codeName)="data">
              <strong class="text-dark font-weight-bold">{{
                data.item.codeName.toUpperCase()
              }}</strong>
            </template>
            <template #cell(alias)="data">
              <strong class="text-dark font-weight-bold">{{
                data.item.alias.toUpperCase()
              }}</strong>
            </template>

            <template #cell(category)="data">
              <!-- <strong class="text-dark font-weight-bold">{{
                  data.item.category
                }}</strong> -->
              <b-button
                v-if="data.item.category == 'Input'"
                variant="flat-dark"
                pill
                :disabled="true"
                style="font-weight:bold"
              >
                {{ data.item.category }}
              </b-button>
              <b-button
                v-if="data.item.category == 'Math Operators'"
                variant="flat-success"
                pill
                :disabled="true"
              >
                {{ data.item.category }}
              </b-button>
              <b-button
                v-if="data.item.category == 'Numeric'"
                variant="flat-primary"
                pill
                :disabled="true"
              >
                {{ data.item.category }}
              </b-button>
              <b-button
                v-if="data.item.category == 'Formula'"
                variant="flat-info"
                pill
                :disabled="true"
                style="font-weight:bold"
              >
                {{ data.item.category }}
              </b-button>
              <b-button
                v-if="data.item.category == 'Lookup Table'"
                variant="flat-warning"
                pill
                :disabled="true"
                style="font-weight:bold"
              >
                {{ data.item.category }}
              </b-button>
              <b-button
                v-if="data.item.category == 'Conditional'"
                variant="flat-danger"
                pill
                :disabled="true"
                style="font-weight:bold"
              >
                {{ data.item.category }}
              </b-button>
            </template>

            <template #cell(isOutput)="data">
              <strong class="text-dark font-weight-bold">{{
                data.item.isOutput == true ? "Yes" : "No"
              }}</strong>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="updateFormulModal(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Update Formul</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteFormulModal(data.item.id)">
                  <feather-icon icon="DeleteIcon" />
                  <span class="align-middle ml-50">Delete Formul</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2 mt-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
              </b-col>

              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- update formul modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Edit Formul"
      scrollable
      size="xl"
      :ok-title="
        updateIfElseConditional ? 'Update Conditional Formul' : 'update'
      "
      modal-class="modal-height"
      v-model="showUpdateFormul"
      @hidden="resetFormul"
      @ok="updateFormul"
    >
      <validation-observer ref="updateMetricsRules">
        <b-form>
          <b-row class="mt-1" align-h="between">
            <!-- Update title -->
            <!-- <b-col md="6" xl="6" class="mb-1">
              <b-form-group label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Update Title"
                  style="width: 100%;"
                  rules="required"
                >
                  <b-form-input
                    id="basicInput"
                    v-model="updateFormulData.codeName"
                    :trim="true"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Update Title"
                    @change="updateCheckFormulTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <!-- Alias -->
            <b-col md="6" xl="6" sm="12" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Alias"
                style="width: 100%;"
                rules="required"
              >
                <b-form-group label-for="basicInput">
                  <b-form-input
                    id="basicInput"
                    v-model="updateFormulData.alias"
                    :trim="true"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Alias"
                    ref="formulTitle"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- isOutput &  Intr Formula-->
            <b-col md="6" xl="2" sm="12">
              <b-form-checkbox
                v-model="updateFormulData.isOutput"
                class="custom-control-secondary checkbox-label"
              >
                See the Result
              </b-form-checkbox>
              <hr style="margin-top:0.3rem;margin-bottom:0.3rem" />
              <b-form-checkbox
                v-model="updateFormulData.intrFormul"
                class="custom-control-secondary checkbox-label"
              >
                Intermediate Formula
              </b-form-checkbox>
            </b-col>
          </b-row>
          <hr />
          <b-row class="mt-1 mb-5">
            <b-col
              md="6"
              xl="3"
              class="mb-1"
              v-for="(operation, index) in updateOperations"
              :key="index"
              v-if="!updateIfElseConditional"
            >
              <b-form-group>
                <!-- select type -->
                <validation-provider
                  #default="{ errors }"
                  name="Metric"
                  style="width: 100%;"
                >
                  <v-select
                    class="animate__animated animate__fadeIn"
                    v-if="operation.selected == 0"
                    v-model="operation.type"
                    label="title"
                    placeholder="Select Metrics Type"
                    :state="errors.length > 0 ? false : null"
                    @option:selected="updateSelectedOperation(operation, index)"
                    @open="openUpdateInputSelectTypes(operation, index)"
                    :selectable="(option) => option.disabled"
                    :clearable="false"
                    :options="updateOption"
                    ref="dropdown"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- input -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Input'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Input"
                    rules="required"
                    class="ml-1"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      :reduce="(val) => val"
                      placeholder="Select Input Type"
                      :clearable="false"
                      :options="inputOptions"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="
                        updateSelectedInputOperation(operation, index)
                      "
                      :disabled="operation.disabled"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="updateDoubleclick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextModalInput(operation, index)"
                      :disabled="updateOperations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="updateOperations.length == 1"
                      @click="removetModalInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- numeric -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Numeric'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="numeric|positive"
                    rules="required"
                    style="width: 100%;"
                  >
                    <b-form-input
                      class="animate__animated animate__fadeIn"
                      v-model.number="operation.value"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      id="basicInput"
                      placeholder="Numeric"
                      :disabled="operation.disabled"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="updateDoubleclick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextModalInput(operation, index)"
                      :disabled="updateOperations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="updateOperations.length == 1"
                      @click="removetModalInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Math operators -->
                <div
                  v-if="
                    operation.selected == 1 &&
                      operation.type == 'Math Operators'
                  "
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Math Operators"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      label="title"
                      :reduce="(val) => val"
                      :clearable="false"
                      :options="mathOptions"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="
                        updateSelectedMathOperation(operation, index)
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="updateDoubleclick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextModalInput(operation, index)"
                      :disabled="updateOperations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="updateOperations.length == 1"
                      @click="removetModalInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Formula -->
                <div
                  v-if="operation.selected == 1 && operation.type == 'Formula'"
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Formula"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      :options="checkedFormulOptions"
                      placeholder="Select Formula"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        updateSelectedFormulOperation(operation, index)
                      "
                      label="alias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="updateDoubleclick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextModalInput(operation, index)"
                      :disabled="updateOperations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="updateOperations.length == 1"
                      @click="removetModalInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Lookup Table select table -->
                <div
                  v-if="
                    operation.selected == 1 && operation.type == 'Lookup Table'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lookup Table"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-model="operation.value"
                      :options="lookupTableOptions"
                      placeholder="Select Lookup Table"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        updateSelectedLookupOperation(operation, index)
                      "
                      label="table"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="updateDoubleclick(operation, index)"
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addNewNextModalInput(operation, index)"
                      :disabled="operations.length == 3"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Add New Input Next</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="operations.length == 1"
                      @click="removetModalInput(operation, index)"
                    >
                      <feather-icon icon="MinusIcon" />
                      <span class="align-middle ml-50">Remove This Input</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <!-- Lookup Table select & result columns -->
                <div
                  v-if="
                    operation.selected == 2 && operation.type == 'Lookup Table'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn lookup-select-placeholder"
                >
                  <!-- select column -->
                  <validation-provider
                    #default="{ errors }"
                    name="Select Column"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="
                        'Selected table column: ' + operation.lookup.selectC
                      "
                      v-model="operation.lookup.selectC"
                      :options="operation.columns"
                      placeholder="Select Column"
                      :reduce="(val) => val.column"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @open="updateFilterSelectedColumn(operation, index)"
                      @option:selected="
                        updateSelectedLookupColumnsOperation(operation, index)
                      "
                      :selectable="(option) => option.disabled"
                      label="column"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>

                  <!-- result column -->
                  <validation-provider
                    #default="{ errors }"
                    name="Result column"
                    rules="required"
                    style="width: 100%;"
                    class="ml-1"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="
                        'Table column to result: ' + operation.lookup.resultC
                      "
                      v-model="operation.lookup.resultC"
                      :options="operation.columns"
                      placeholder="Result Columns"
                      :reduce="(val) => val.column"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @open="updateFilterSelectedResultColumn(operation, index)"
                      @option:selected="
                        updateResultLookupColumnsOperation(operation, index)
                      "
                      :selectable="(option) => option.disabled"
                      label="column"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                </div>

                <!-- select lookup Input or Formula type -->
                <div
                  v-if="
                    operation.selected == 2 &&
                      operation.type == 'Lookup Operation'
                  "
                  class="d-flex align-items-center animate__animated animate__fadeIn lookup-select-placeholder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lookup operations"
                    style="width: 100%;"
                  >
                    <v-select
                      class="animate__animated animate__fadeIn"
                      v-model="operation.type"
                      label="title"
                      placeholder="Select Lookup operation Type"
                      :state="errors.length > 0 ? false : null"
                      @option:selected="
                        updateSelectedMiddleLookupOperation(operation, index)
                      "
                      :clearable="false"
                      :options="lookupOption"
                      ref="dropdown"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <!-- Look up type formula -->
                <div
                  v-if="operation.selected == 2 && operation.type == 'Formula'"
                  class="d-flex align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Formula"
                    rules="required"
                    style="width: 100%;"
                  >
                    <v-select
                      v-b-tooltip.hover.v-dark
                      :title="'Action in the table: ' + operation.type"
                      v-model="operation.value"
                      :options="formulOptions"
                      placeholder="Select Formula"
                      :reduce="(val) => val"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @option:selected="
                        updateSelectedFormulOperation(operation, index)
                      "
                      label="alias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <!-- Lookup Not Found -->
                <div
                  v-if="
                    operation.selected == 2 &&
                      operation.type == 'Lookup Not Found'
                  "
                  class="d-flex justify-content-center align-items-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Not Found Variable"
                    rules="required|positive"
                    style="width: 100%;"
                  >
                    <b-form-input
                      v-b-tooltip.hover.v-dark
                      :title="'Result value when no value is found in table'"
                      class="animate__animated animate__fadeIn"
                      v-model.number="operation.value"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      id="basicInput"
                      placeholder="Can't Find"
                      :disabled="operation.disabled"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    class="input-dropdown"
                  >
                    <template #button-content class="p-1">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="
                        updateDoubleDefaultOprationClick(operation, index)
                      "
                    >
                      <feather-icon icon="RotateCcwIcon" />
                      <span class="align-middle ml-50">Change Input type</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="2"
              sm="12"
              v-if="!updateIfElseConditional"
            >
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-dark"
                  class="btn-icon animate__animated animate__fadeIn"
                  @click="removeLastModalInput"
                  :disabled="
                    updateOperations.length == 1 || updateMinusBtnDisabled
                  "
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-dark"
                  class="btn-icon ml-1"
                  @click="addNewModalInput"
                  :disabled="updateOperations.length == 3"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-form-group>
            </b-col>

            <!-- update If Else Conditional ----------------------------------------------->
            <b-col
              cols="12"
              v-if="updateIfElseConditional"
              class=" animate__animated animate__fadeIn mt-2"
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                class="input-dropdown"
                style="float: right;"
              >
                <template #button-content class="p-1">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="22"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="updateChangeCalType()">
                  <feather-icon icon="RotateCcwIcon" />
                  <span class="align-middle ml-50">Change Input type</span>
                </b-dropdown-item>
              </b-dropdown>
              <b-row class="d-flex align-items-start justify-content-center">
                <!-- select first conditional type -->
                <b-col cols="3" class="mt-1 animate__animated animate__fadeIn">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Condition Metric"
                      style="width: 100%;"
                      v-if="updateConditionObj.firstSelect.type == null"
                      class="animate__animated animate__fadeIn"
                    >
                      <v-select
                        v-model="updateConditionObj.firstSelect.type"
                        label="title"
                        placeholder="First Conditional Type"
                        :state="errors.length > 0 ? false : null"
                        :selectable="(option) => option.disabled"
                        :clearable="false"
                        :options="conditionOption"
                        @option:selected="
                          updateSelectedConditionOperation(
                            updateConditionObj.firstSelect
                          )
                        "
                        @open="updateOpenConditionInputSelectTypes('first')"
                        :reduce="(val) => val.title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <!-- numeric -->
                    <div
                      v-if="updateConditionObj.firstSelect.type == 'Numeric'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="first condition numeric"
                        style="width: 100%;"
                        rules="required|positive"
                      >
                        <b-form-input
                          class="animate__animated animate__fadeIn"
                          v-model.number="updateConditionObj.firstSelect.value"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          id="basicInput"
                          placeholder="Numeric"
                          @change="
                            updateSelectedConditionNumericOperation(
                              updateConditionObj.firstSelect,
                              'first'
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateDoubleConditionclick('first')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <!-- formula -->
                    <div
                      v-if="updateConditionObj.firstSelect.type == 'Formula'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="first condition formula"
                        style="width: 100%;"
                        rules="required"
                      >
                        <v-select
                          v-model="updateConditionObj.firstSelect.value"
                          :options="formulOptions"
                          placeholder="Select Formula"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                          :clearable="false"
                          @option:selected="
                            updateSelectedConditionFormulOperation(
                              updateConditionObj.firstSelect,
                              'first'
                            )
                          "
                          label="alias"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          @click="updateDoubleConditionclick('first')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- select operation type -->
                <b-col cols="2" class="mt-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Condition operation"
                      style="width: 100%;"
                      rules="required"
                    >
                      <v-select
                        class="animate__animated animate__fadeIn"
                        v-model="updateConditionObj.conditionOperation"
                        placeholder="Select Condition Operation"
                        :state="errors.length > 0 ? false : null"
                        :clearable="false"
                        :options="conditionOperationOption"
                        :reduce="(val) => val"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- select second conditional type -->
                <b-col cols="3" class="mt-1 animate__animated animate__fadeIn">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="second condition metric"
                      style="width: 100%;"
                      v-if="updateConditionObj.secondSelect.type == null"
                      class="animate__animated animate__fadeIn"
                    >
                      <v-select
                        v-model="updateConditionObj.secondSelect.type"
                        label="title"
                        placeholder="Second Conditional Type"
                        :state="errors.length > 0 ? false : null"
                        :selectable="(option) => option.disabled"
                        :clearable="false"
                        :options="conditionOption"
                        @option:selected="
                          updateSelectedConditionOperation(
                            updateConditionObj.secondSelect
                          )
                        "
                        @open="updateOpenConditionInputSelectTypes('second')"
                        :reduce="(val) => val.title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <!-- numeric -->
                    <div
                      v-if="updateConditionObj.secondSelect.type == 'Numeric'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="second condition numeric"
                        rules="required|positive"
                        style="width: 100%;"
                      >
                        <b-form-input
                          class="animate__animated animate__fadeIn"
                          v-model.number="updateConditionObj.secondSelect.value"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          id="basicInput"
                          placeholder="Numeric"
                          @change="
                            updateSelectedConditionNumericOperation(
                              updateConditionObj.secondSelect,
                              'second'
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateDoubleConditionclick('second')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <!-- formula -->
                    <div
                      v-if="updateConditionObj.secondSelect.type == 'Formula'"
                      class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="second condition formula"
                        rules="required"
                        style="width: 100%;"
                      >
                        <v-select
                          v-model="updateConditionObj.secondSelect.value"
                          :options="formulOptions"
                          placeholder="Select Formula"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                          :clearable="false"
                          @option:selected="
                            updateSelectedConditionFormulOperation(
                              updateConditionObj.secondSelect,
                              'second'
                            )
                          "
                          label="alias"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        class="input-dropdown"
                      >
                        <template #button-content class="p-1">
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          @click="updateDoubleConditionclick('second')"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50"
                            >Change Condition Input type</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- if else section -->
              <b-row
                align-h="around"
                class=" mt-1 animate__animated animate__fadeIn"
                v-if="updateVisibleIfElse"
              >
                <!-- if situation -->
                <b-col cols="3" md="3" xl="3" lg="3" sm="12">
                  <hr />
                  <b-card
                    border-variant="light"
                    header-text-variant="white"
                    header="If Yes"
                    class="text-center"
                    header-bg-variant="success"
                  >
                    <b-card-text class="mt-2">
                      <!-- select if type -->
                      <validation-provider
                        #default="{ errors }"
                        name="if condition metric"
                        style="width: 100%;"
                        v-if="updateConditionObj.ifSelect.type == null"
                        class="animate__animated animate__fadeIn mt-2"
                      >
                        <v-select
                          v-model="updateConditionObj.ifSelect.type"
                          label="title"
                          placeholder="If Conditional Type"
                          :state="errors.length > 0 ? false : null"
                          :selectable="(option) => option.disabled"
                          :clearable="false"
                          :options="conditionOption"
                          @option:selected="
                            updateSelectedIfElseConditionOperation(
                              updateConditionObj.ifSelect
                            )
                          "
                          :reduce="(val) => val.title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <!-- if numeric -->
                      <div
                        v-if="updateConditionObj.ifSelect.type == 'Numeric'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="if condition numeric"
                          rules="required|positive"
                          style="width: 100%;"
                        >
                          <b-form-input
                            class="animate__animated animate__fadeIn"
                            v-model.number="updateConditionObj.ifSelect.value"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            id="basicInput"
                            placeholder="Numeric"
                            @change="
                              updateSelectedIfElseConditionNumericOperation(
                                updateConditionObj.ifSelect,
                                'if'
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click="updateDoubleIfElseConditionclick('if')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change If Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <!-- if formula -->
                      <div
                        v-if="updateConditionObj.ifSelect.type == 'Formula'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="if condition formula"
                          rules="required"
                          style="width: 100%;"
                        >
                          <v-select
                            v-model="updateConditionObj.ifSelect.value"
                            :options="formulOptions"
                            placeholder="Select Formula"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :clearable="false"
                            @option:selected="
                              updateSelectedIfElseConditionFormulOperation(
                                updateConditionObj.ifSelect,
                                'if'
                              )
                            "
                            label="alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>

                          <b-dropdown-item
                            @click="updateDoubleIfElseConditionclick('if')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change If Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>

                <!-- else situation --------------->
                <b-col cols="3" md="3" xl="3" lg="3" sm="12">
                  <hr />
                  <b-card
                    border-variant="light"
                    header-text-variant="white"
                    header="If No"
                    class="text-center"
                    header-bg-variant="danger"
                  >
                    <b-card-text class="mt-2">
                      <!-- select else type -->
                      <validation-provider
                        #default="{ errors }"
                        name="else condition metric"
                        style="width: 100%;"
                        v-if="updateConditionObj.elseSelect.type == null"
                        class="animate__animated animate__fadeIn mt-2"
                      >
                        <v-select
                          v-model="updateConditionObj.elseSelect.type"
                          label="title"
                          placeholder="Else Conditional Type"
                          :state="errors.length > 0 ? false : null"
                          :selectable="(option) => option.disabled"
                          :clearable="false"
                          :options="conditionOption"
                          @option:selected="
                            updateSelectedIfElseConditionOperation(
                              updateConditionObj.elseSelect
                            )
                          "
                          :reduce="(val) => val.title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <!-- else numeric -->
                      <div
                        v-if="updateConditionObj.elseSelect.type == 'Numeric'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="else condition numeric"
                          rules="required|positive"
                          style="width: 100%;"
                        >
                          <b-form-input
                            class="animate__animated animate__fadeIn"
                            v-model.number="updateConditionObj.elseSelect.value"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            id="basicInput"
                            placeholder="Numeric"
                            @change="
                              updateSelectedIfElseConditionNumericOperation(
                                updateConditionObj.elseSelect,
                                'else'
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click="updateDoubleIfElseConditionclick('else')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change Else Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <!-- else formula -->
                      <div
                        v-if="updateConditionObj.elseSelect.type == 'Formula'"
                        class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="else condition formula"
                          rules="required"
                          style="width: 100%;"
                        >
                          <v-select
                            v-model="updateConditionObj.elseSelect.value"
                            :options="formulOptions"
                            placeholder="Select Formula"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :clearable="false"
                            @option:selected="
                              updateSelectedIfElseConditionFormulOperation(
                                updateConditionObj.elseSelect,
                                'else'
                              )
                            "
                            label="alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          class="input-dropdown"
                        >
                          <template #button-content class="p-1">
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>

                          <b-dropdown-item
                            @click="updateDoubleIfElseConditionclick('else')"
                          >
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50"
                              >Change Else Condition Input type</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import formulStoreModule from "../formulStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import { BTable } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, alpha } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import _ from "lodash";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    vSelect,
    BTable,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  setup() {
    const FORMUL_APP_STORE_MODULE_NAME = "formul";
    // Register module
    if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
      store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
    });
  },

  created() {},

  mounted() {
    this.getFormuls();
    this.getTableWithColumns();
  },

  data() {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    return {
      dateNow,
      formulData: {
        codeName: null,
        alias: null,
        type: null,
        category: null,
        isOutput: false,
        intrFormul: false,
        formula: [],
      },
      formShow: false,
      userDataInfo: null,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      perPage: 10,
      minusBtnDisabled: false,
      option: [
        { title: "Numeric", disabled: true },
        { title: "Math Operators", disabled: true },
        { title: "Input", disabled: true },
        { title: "Formula", disabled: true },
        { title: "Lookup Table", disabled: true },
        { title: "Conditional", disabled: true },
      ],
      lookupOption: [{ title: "Formula", disabled: true }],
      updateOption: [
        { title: "Numeric", disabled: true },
        { title: "Math Operators", disabled: true },
        { title: "Input", disabled: true },
        { title: "Formula", disabled: true },
        { title: "Lookup Table", disabled: true },
        { title: "Conditional", disabled: true },
      ],
      conditionOption: [
        { title: "Numeric", disabled: true },
        { title: "Formula", disabled: true },
      ],
      formulOptions: [],
      checkedFormulOptions: [],
      lookupTableOptions: [],
      mathOptions: [
        { title: " + " },
        { title: " - " },
        { title: " / " },
        { title: " * " },
      ],
      inputOptions: ["Number", "Float"],
      formulTableHeaders: [
        { key: "id", sortable: true, class: "text-center" },
        {
          key: "codeName",
          label: "Title",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "alias",
          label: "Alias",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "category",
          label: "Type",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "isOutput",
          label: "Output",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "ACTIONS",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      operations: [
        {
          id: 1,
          selected: 0,
          type: "",
          value: null,
          disabled: false,
        },
      ],
      title: null,
      formulTitle: null,
      tableData: [],
      tableFormuls: [],
      Formula: [],
      clickCounter: 0,
      calculateFormul: "",
      counter: 0,
      loading: false,

      //if else conditional
      ifElseConditional: false,
      visibleIfElse: false,
      conditionOperationOption: ["=", ">", "<", ">=", "<="],
      conditionObj: {
        firstSelect: {
          type: null,
          value: null,
        },
        secondSelect: {
          type: null,
          value: null,
        },
        ifSelect: {
          type: null,
          value: null,
        },
        elseSelect: {
          type: null,
          value: null,
        },
        firstCondition: null,
        secondCondition: null,
        conditionOperation: null,
        category: "Conditional",
        if: null,
        else: null,
      },

      // update if else conditional
      updateIfElseConditional: false,
      updateVisibleIfElse: false,
      updateConditionObj: {
        firstSelect: {
          type: null,
          value: null,
        },
        secondSelect: {
          type: null,
          value: null,
        },
        ifSelect: {
          type: null,
          value: null,
        },
        elseSelect: {
          type: null,
          value: null,
        },
        firstCondition: null,
        secondCondition: null,
        conditionOperation: null,
        category: "Conditional",
        if: null,
        else: null,
      },

      //update formul modal

      showUpdateFormul: false,
      updateMinusBtnDisabled: false,
      updateOperations: [],
      updateFormulData: {
        id: 0,
        codeName: null,
        alias: null,
        type: null,
        category: null,
        isOutput: false,
        intrFormul: false,
        formula: [],
      },
      preUpdateCodeName: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  methods: {
    kFormatter,

    doubleclick(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.selected = 0;
          operation.value = null;
          operation.type = "";
        }
      });
    },
    addNewNextInput(o, i) {
      this.$refs.metricsRules.validate().then((success) => {
        if (success) {
          this.operations.splice(i + 1, 0, {
            id: this.operations.length + 1,
            selected: 0,
            type: "",
            value: null,
            disabled: false,
          });
        }
      });
    },
    removetInput(o, i) {
      if (this.operations.length > 1) {
        this.operations.splice(i, 1);
      }
    },
    dateFormat(val) {
      return moment(val).format("DD-MM-YYYYY HH:mm");
    },
    addNewInput() {
      this.$refs.metricsRules.validate().then((success) => {
        if (success) {
          this.operations.push({
            id: this.operations.length + 1,
            selected: 0,
            type: "",
            value: null,
            disabled: false,
          });
        }
      });
    },
    removeLastInput() {
      this.operations.pop();
    },
    openInputSelectTypes(o, i) {
      if (this.operations.length > 1) {
        if (this.operations[i - 1].type == "Math Operators") {
          this.option[0].disabled = true;
          this.option[1].disabled = false;
          this.option[2].disabled = false;
          this.option[3].disabled = true;
          this.option[4].disabled = true;
          this.option[5].disabled = false;
          if (this.operations.length == 3) {
            this.option[4].disabled = false;
            this.option[5].disabled = false;
          }
        } else if (this.operations[i - 1].type == "Numeric") {
          this.option[0].disabled = false;
          this.option[1].disabled = true;
          this.option[2].disabled = false;
          this.option[3].disabled = false;
          this.option[4].disabled = false;
          this.option[5].disabled = false;
        } else if (this.operations[i - 1].type == "Input") {
          this.option[0].disabled = false;
          this.option[1].disabled = true;
          this.option[2].disabled = false;
          this.option[3].disabled = false;
          this.option[4].disabled = false;
          this.option[5].disabled = false;
        } else if (this.operations[i - 1].type == "Formula") {
          this.option[0].disabled = false;
          this.option[1].disabled = true;
          this.option[2].disabled = false;
          this.option[3].disabled = false;
          this.option[4].disabled = false;
          this.option[5].disabled = false;
        } else if (this.operations[i - 1].type == "Lookup Table") {
          this.option[0].disabled = false;
          this.option[1].disabled = false;
          this.option[2].disabled = false;
          this.option[3].disabled = false;
          this.option[4].disabled = false;
          this.option[5].disabled = false;
        }
      } else if (this.operations.length == 1) {
        this.option[0].disabled = false;
        this.option[1].disabled = false;
        this.option[2].disabled = true;
        this.option[3].disabled = true;
        this.option[4].disabled = true;
        this.option[5].disabled = true;
      }
    },
    selectedOperation(o, i) {
      if (o.type.title == "Conditional") {
        this.ifElseConditional = true;
      } else {
        this.ifElseConditional = false;
        this.operations.forEach((operation, index) => {
          if (index == i) {
            operation.selected = 1;
            operation.type = o.type.title;
          }
        });
      }
    },
    selectedMathOperation(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.value = o.value;
        }
      });
    },
    selectedInputOperation(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.value = o.value;
        }
      });
    },
    selectedFormulOperation(o, i) {
      // o.disabled = true;
      //this.operations.splice(i, 1);
      // o.value.formul.forEach((f) => {
      //   f.disabled = true;
      //   this.operations.push(f);
      // });
    },

    // look up operations
    selectedLookupOperation(o, i) {
      o.columns = [];
      o.value.columns.forEach((c) => {
        o.columns.push({
          column: c,
          disabled: true,
        });
      });

      o.lookup = {
        table: o.value.table,
        selectC: null,
        resultC: null,
      };

      this.operations.push(
        {
          id: this.operations.length + 1,
          selected: 2,
          type: "Lookup Operation",
          value: null,
          disabled: false,
        },
        {
          id: this.operations.length + 2,
          selected: 2,
          type: "Lookup Not Found",
          value: null,
          disabled: false,
        }
      );
      this.minusBtnDisabled = true;
      o.selected = 2;
    },
    selectedLookupColumnsOperation(o, i) {
      // console.log(o)
    },
    resultLookupColumnsOperation(o, i) {
      // console.log(o);
    },
    filterSelectedResultColumn(o, i) {
      o.columns.forEach((c) => {
        if (o.lookup.selectC != null) {
          if (c.column == o.lookup.selectC) {
            c.disabled = false;
          } else {
            c.disabled = true;
          }
        }
      });
    },
    filterSelectedColumn(o, i) {
      o.columns.forEach((c) => {
        if (o.lookup.resultC != null) {
          if (c.column == o.lookup.resultC) {
            c.disabled = false;
          } else {
            c.disabled = true;
          }
        }
      });
    },
    selectedMiddleLookupOperation(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.selected = 2;
          operation.type = o.type.title;
          operation.value = null;
        }
      });
    },
    doubleLookupClick(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.selected = 2;
          operation.value = null;
          operation.type = "Lookup Operation";
        }
      });
    },
    doubleDefaultOprationClick(o, i) {
      this.operations = [
        {
          id: this.operations.length + 1,
          selected: 0,
          type: "",
          value: null,
          disabled: false,
        },
      ];
      this.minusBtnDisabled = false;
    },
    //*********** Look up*/

    selectedPercentOperation(o, i) {
      this.operations.forEach((operation, index) => {
        if (index == i) {
          operation.specialFormul = "*" + o.value + "/100";
          operation.value = "%" + o.value;
        }
      });
    },
    addFormula() {
      this.$refs.metricsRules.validate().then((success) => {
        if (success) {
          this.formulData.formula = [];
          if (
            this.operations.length == 1 &&
            this.operations.find((o) => o.type === "Formula") == undefined
          ) {
            if (this.operations[0].type == "Input") {
              this.formulData.category = this.operations[0].type;
              this.formulData.type = this.operations[0].value;
            } else {
              this.formulData.category = this.operations[0].type;
            }
          } else if (
            this.operations.length == 1 &&
            this.operations.find((o) => o.type === "Formula") != undefined
          ) {
            this.operations.push({
              id: this.operations.length + 1,
              selected: 1,
              type: "Math Operators",
              value: { title: " * " },
              disabled: false,
            });

            this.operations.push({
              id: this.operations.length + 1,
              selected: 1,
              type: "Numeric",
              value: 1,
              disabled: false,
            });

            this.formulData.category = "Formula";
            this.operations.forEach((o) => {
              if (o.type == "Formula") {
                this.formulData.formula.push({
                  id: o.value.id,
                  codeName: o.value.codeName,
                  category: o.value.category,
                  usedFormul: true,
                });
              } else {
                this.formulData.category = "Formula";

                if (o.type == "Math Operators") {
                  this.formulData.formula.push({
                    operation: o.value.title,
                    category: o.type,
                    usedFormul: false,
                  });
                } else {
                  this.formulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              }
            });
            //this.checkFormulaToastMessage();
            //return;
          } else {
            if (
              this.operations.find((o) => o.type === "Lookup Table") !=
              undefined
            ) {
              this.formulData.category = "Lookup Table";
              this.operations.forEach((o) => {
                if (o.type == "Lookup Table") {
                  this.formulData.formula.push({
                    lookup: o.lookup,
                    category: o.type,
                    usedFormul: false,
                  });
                } else if (o.type == "Formula") {
                  this.formulData.formula.push({
                    id: o.value.id,
                    codeName: o.value.codeName,
                    category: o.value.category,
                    usedFormul: true,
                  });
                } else if (o.type == "Lookup Not Found") {
                  this.formulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              });
            } else if (
              this.operations.find((o) => o.type === "Formula") != undefined
            ) {
              this.operations.forEach((o) => {
                if (o.type == "Formula") {
                  this.formulData.formula.push({
                    id: o.value.id,
                    codeName: o.value.codeName,
                    category: o.value.category,
                    usedFormul: true,
                  });
                } else {
                  this.formulData.category = "Formula";

                  if (o.type == "Math Operators") {
                    this.formulData.formula.push({
                      operation: o.value.title,
                      category: o.type,
                      usedFormul: false,
                    });
                  } else {
                    this.formulData.formula.push({
                      operation: o.value,
                      category: o.type,
                      usedFormul: false,
                    });
                  }
                }
              });
            } else {
              this.formulData.category = "Formula";
              this.operations.forEach((o) => {
                if (o.type == "Math Operators") {
                  this.formulData.formula.push({
                    operation: o.value.title,
                    category: o.type,
                    usedFormul: false,
                  });
                } else {
                  this.formulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              });
            }
          }
          if (
            this.operations[this.operations.length - 1].type == "Math Operators"
          ) {
            this.lastValueErrorToastMessage();
          } else if (this.operations[0].type == "Math Operators") {
            this.firstValueErrorToastMessage();
          } else {
            if (
              this.formulData.codeName != null ||
              this.formulData.codeName != ""
            ) {
              this.$store
                .dispatch("formul/checkFormulTitle", {
                  title: this.formulData.codeName,
                })
                .then((res) => {
                  if (res.data.existing == 1) {
                    this.checkTitleToastMessage();
                  } else {
                    this.$store
                      .dispatch("formul/addNewFormul", this.formulData)
                      .then((response) => {
                        this.getFormuls();
                        this.toastMessage("Formula");
                        //clear
                        (this.formulData = {
                          codeName: null,
                          alias: null,
                          type: null,
                          category: null,
                          isOutput: false,
                          intrFormul: false,
                          formula: [],
                        }),
                          (this.operations = [
                            {
                              id: 1,
                              selected: 0,
                              type: "",
                              value: null,
                              disabled: false,
                            },
                          ]);
                        //finish clear
                      })
                      .catch((error) => {
                        console.log("error create data ", error);
                      });
                  }
                })
                .catch((error) => {
                  console.log("error check data ", error);
                });
            }
          }
        }
      });
    },
    conditionalAddFormula() {
      this.$refs.metricsRules.validate().then((success) => {
        if (success) {
          this.formulData.formula = [];
          this.formulData.category = "Conditional";
          this.formulData.formula.push({
            firstCondition: this.conditionObj.firstCondition,
            secondCondition: this.conditionObj.secondCondition,
            conditionOperation: this.conditionObj.conditionOperation,
            ifState: this.conditionObj.if,
            elseState: this.conditionObj.else,
          });
          if (
            this.formulData.codeName != null ||
            this.formulData.codeName != ""
          ) {
            this.$store
              .dispatch("formul/checkFormulTitle", {
                title: this.formulData.codeName,
              })
              .then((res) => {
                if (res.data.existing == 1) {
                  this.checkTitleToastMessage();
                } else {
                  this.$store
                    .dispatch("formul/addNewFormul", this.formulData)
                    .then((response) => {
                      this.getFormuls();
                      this.toastMessage("Conditional Formula");
                      //clear
                      this.changeCalType();
                      this.formulData = {
                        codeName: null,
                        alias: null,
                        type: null,
                        category: null,
                        isOutput: false,
                        intrFormul: false,
                        formula: [],
                      };
                      //finish clear
                    })
                    .catch((error) => {
                      console.log("error conditional create data ", error);
                    });
                }
              })
              .catch((error) => {
                console.log("error conditional check data ", error);
              });
          }
        }
      });
    },
    getFormuls() {
      this.loading = true;

      this.$store
        .dispatch("formul/fetchFormulsTable")
        .then((response) => {
          this.totalRows = response.data.length;
          this.tableFormuls = [];
          this.formulOptions = [];
          response.data.forEach((formul) => {
            this.tableFormuls.push(formul);
            this.formulOptions.push(formul);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log("error get data ", error);
        });
    },
    checkFormulTitle() {
      if (this.formulData.codeName != null || this.formulData.codeName != "") {
        this.$store
          .dispatch("formul/checkFormulTitle", {
            title: this.formulData.codeName,
          })
          .then((res) => {
            if (res.data.existing == 1) {
              this.checkTitleToastMessage();
            }
          })
          .catch((error) => {
            console.log("error get data ", error);
          });
      }
    },
    getTableWithColumns() {
      this.$store
        .dispatch("formul/fetchTablesWithColumns")
        .then((res) => {
          this.lookupTableOptions = res.data;
        })
        .catch((error) => {
          console.log("error get table with columns data ", error);
        });
    },

    //update modal functions

    checkFormulOptionsFunc(data) {
      let removeArray = [];
      let foreignArray = [];
      let data2 = JSON.parse(JSON.stringify(this.checkedFormulOptions));

      this.checkedFormulOptions.forEach((formul, index) => {
        if (data.id == formul.id) {
          // this.checkedFormulOptions.splice(index, 1);
          removeArray.push(formul);
        } else {
          if (formul.category == "Formula" && formul.formula.length > 0) {
            formul.formula.forEach((f, i) => {
              if (data.id == f.id) {
                // this.checkedFormulOptions.splice(index, 1);
                foreignArray.push(formul);
                removeArray.push(formul);
              }
            });
          }
        }
      });
      if (removeArray.length > 0) {
        removeArray.forEach((formul) => {
          this.checkedFormulOptions.forEach((f, index) => {
            if (f.id == formul.id) {
              this.checkedFormulOptions.splice(index, 1);
            }
          });
        });
      }

      if (foreignArray.length > 0) {
        foreignArray.forEach((f) => {
          this.checkFormulOptionsFunc(f);
        });
      }
    },

    updateFormulModal(data) {
      const self = this;

      this.checkedFormulOptions = JSON.parse(
        JSON.stringify(this.formulOptions)
      );

      if (this.checkedFormulOptions.length > 0) {
        this.checkFormulOptionsFunc(data);
      }

      let updateData = JSON.parse(JSON.stringify(data));
      this.updateFormulData.id = updateData.id;
      this.updateFormulData.codeName = updateData.codeName;
      this.preUpdateCodeName = JSON.parse(
        JSON.stringify(this.updateFormulData.codeName)
      );
      this.updateFormulData.alias = updateData.alias;
      this.updateFormulData.isOutput = updateData.isOutput == 1 ? true : false;
      this.updateFormulData.intrFormul =
        updateData.intrFormul == 1 ? true : false;

      if (updateData.category == "Conditional") {
        let conditionData = data.formula[0];
        this.updateIfElseConditional = true;
        this.updateFormulData.category = 'Conditional'

        this.updateConditionObj = {
          firstSelect: {
            type: conditionData.firstCondition.category,
            value:
              conditionData.firstCondition.category == "Formula"
                ? this.tableFormuls.find(
                    (o) => o.codeName === conditionData.firstCondition.codeName
                  )
                : conditionData.firstCondition.operation,
          },
          secondSelect: {
            type: conditionData.secondCondition.category,
            value:
              conditionData.secondCondition.category == "Formula"
                ? this.tableFormuls.find(
                    (o) => o.codeName === conditionData.secondCondition.codeName
                  )
                : conditionData.secondCondition.operation,
          },
          ifSelect: {
            type: conditionData.ifState.category,
            value:
              conditionData.ifState.category == "Formula"
                ? this.tableFormuls.find(
                    (o) => o.codeName === conditionData.ifState.codeName
                  )
                : conditionData.ifState.operation,
          },
          elseSelect: {
            type: conditionData.elseState.category,
            value:
              conditionData.elseState.category == "Formula"
                ? this.tableFormuls.find(
                    (o) => o.codeName === conditionData.elseState.codeName
                  )
                : conditionData.elseState.operation,
          },
          firstCondition: conditionData.firstCondition,
          secondCondition: conditionData.secondCondition,
          conditionOperation: conditionData.conditionOperation,
          category: "Conditional",
          if: conditionData.ifState,
          else: conditionData.elseState,
        };
      } else {
        if (updateData.formula == null || updateData.formula.length == 0) {
          if (updateData.category == "Input") {
            self.updateOperations.push({
              id: self.updateOperations.length + 1,
              selected: 1,
              type: updateData.category,
              value: updateData.type,
              disabled: false,
            });
          } else {
            self.updateOperations.push({
              id: self.updateOperations.length + 1,
              selected: 1,
              type: updateData.category,
              value: updateData.type,
              disabled: false,
            });
          }
        } else {
          if (updateData.category == "Lookup Table") {
            this.updateMinusBtnDisabled = true;
            updateData.formula.forEach((f, index) => {
              if (f.category == "Lookup Table" && index == 0) {
                let table = this.lookupTableOptions.find(
                  (o) => o.table === f.lookup.table
                );

                let columns = [];

                table.columns.forEach((c) => {
                  columns.push({
                    column: c,
                    disabled: true,
                  });
                });

                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 2,
                  type: f.category,
                  lookup: f.lookup,
                  columns: columns,
                  value: table,
                  disabled: false,
                });
              } else if (f.category == "Formula" && index == 1) {
                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 2,
                  type: f.category,
                  value: this.formulOptions.find(
                    (o) => o.codeName === f.codeName
                  ),
                  disabled: false,
                });
              } else if (f.category == "Lookup Table" && index == 1) {
                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 2,
                  type: "Formula",
                  value: this.formulOptions.find(
                    (o) => o.codeName === f.codeName
                  ),
                  disabled: false,
                });
              } else if (f.category == "Lookup Not Found") {
                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 2,
                  type: f.category,
                  value: f.operation,
                  disabled: false,
                });
              }
            });
          } else if (updateData.category == "Formula") {
            updateData.formula.forEach((f) => {
              if (f.usedFormul == true) {
                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 1,
                  type: updateData.category,
                  value: this.formulOptions.find(
                    (o) => o.codeName === f.codeName
                  ),
                  disabled: false,
                });
              } else {
                self.updateOperations.push({
                  id: self.updateOperations.length + 1,
                  selected: 1,
                  type: f.category,
                  value: f.operation,
                  disabled: false,
                });
              }
            });
          }
        }
      }
      this.showUpdateFormul = true;
    },

    updateFormul(bvModalEvent) {
      bvModalEvent.preventDefault();
      
      if(this.updateFormulData.category == 'Conditional') {
        this.$refs.updateMetricsRules.validate().then((success) => {
        if (success) {
          this.updateFormulData.formula = [];

          this.updateFormulData.formula.push({
            firstCondition: this.updateConditionObj.firstCondition,
            secondCondition: this.updateConditionObj.secondCondition,
            conditionOperation: this.updateConditionObj.conditionOperation,
            ifState: this.updateConditionObj.if,
            elseState: this.updateConditionObj.else,
          });

          if (
              this.updateFormulData.codeName != null ||
              this.updateFormulData.codeName != ""
            ) {
              if (this.preUpdateCodeName != this.updateFormulData.codeName) {
                this.$store
                  .dispatch("formul/checkFormulTitle", {
                    title: this.updateFormulData.codeName,
                  })
                  .then((res) => {
                    if (res.data.existing == 1) {
                      this.checkTitleToastMessage();
                    } else {
                      this.$store
                        .dispatch("formul/updateFormul", this.updateFormulData)
                        .then((res) => {
                          this.getFormuls();
                          this.resetFormul();
                          this.editToastMessage("Conditional Formul");
                          this.preUpdateCodeName = null;
                        })
                        .catch((error) => {
                          console.log("error update conditional data ", error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("error update check formul title get data ", error);
                  });
              } else {
                this.$store
                  .dispatch("formul/updateFormul", this.updateFormulData)
                  .then((res) => {
                    this.getFormuls();
                    this.resetFormul();
                    this.editToastMessage("Formul");
                    this.preUpdateCodeName = null;
                  })
                  .catch((error) => {
                    console.log("error update data ", error);
                  });
              }
            }
          
        }
      });
      } else {
        this.$refs.updateMetricsRules.validate().then((success) => {
        if (success) {
          this.updateFormulData.formula = [];

          if (
            this.updateOperations.length == 1 &&
            this.updateOperations.find((o) => o.type === "Formula") == undefined
          ) {
            if (this.updateOperations[0].type == "Input") {
              this.updateFormulData.category = this.updateOperations[0].type;
              this.updateFormulData.type = this.updateOperations[0].value;
            } else {
              this.updateFormulData.category = this.updateOperations[0].type;
            }
          } else if (
            this.updateOperations.length == 1 &&
            this.updateOperations.find((o) => o.type === "Formula") != undefined
          ) {
            this.updateOperations.push({
              id: this.updateOperations.length + 1,
              selected: 1,
              type: "Math Operators",
              value: { title: " * " },
              disabled: false,
            });

            this.updateOperations.push({
              id: this.updateOperations.length + 1,
              selected: 1,
              type: "Numeric",
              value: 1,
              disabled: false,
            });

            this.updateFormulData.category = "Formula";
            this.updateOperations.forEach((o) => {
              if (o.type == "Formula") {
                this.updateFormulData.formula.push({
                  id: o.value.id,
                  codeName: o.value.codeName,
                  category: o.value.category,
                  usedFormul: true,
                });
              } else {
                this.updateFormulData.category = "Formula";

                if (o.type == "Math Operators") {
                  this.updateFormulData.formula.push({
                    operation: o.value.title,
                    category: o.type,
                    usedFormul: false,
                  });
                } else {
                  this.updateFormulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              }
            });
            //this.checkFormulaToastMessage();
            //return;
          } else {
            if (
              this.updateOperations.find((o) => o.type === "Lookup Table") !=
              undefined
            ) {
              this.updateFormulData.category = "Lookup Table";
              this.updateOperations.forEach((o) => {
                if (o.type == "Lookup Table") {
                  this.updateFormulData.formula.push({
                    lookup: o.lookup,
                    category: o.type,
                    usedFormul: false,
                  });
                } else if (o.type == "Formula") {
                  this.updateFormulData.formula.push({
                    id: o.value.id,
                    codeName: o.value.codeName,
                    category: o.value.category,
                    usedFormul: true,
                  });
                } else if (o.type == "Lookup Not Found") {
                  this.updateFormulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              });
            } else if (
              this.updateOperations.find((o) => o.type === "Formula") !=
              undefined
            ) {
              this.updateOperations.forEach((o) => {
                if (o.type == "Formula") {
                  this.updateFormulData.formula.push({
                    id: o.value.id,
                    codeName: o.value.codeName,
                    category: o.value.category,
                    usedFormul: true,
                  });
                } else {
                  this.updateFormulData.category = "Formula";

                  if (o.type == "Math Operators") {
                    this.updateFormulData.formula.push({
                      operation: o.value.title ? o.value.title : o.value,
                      category: o.type,
                      usedFormul: false,
                    });
                  } else {
                    this.updateFormulData.formula.push({
                      operation: o.value,
                      category: o.type,
                      usedFormul: false,
                    });
                  }
                }
              });
            } else {
              this.updateFormulData.category = "Formula";
              this.updateOperations.forEach((o) => {
                if (o.type == "Math Operators") {
                  this.updateFormulData.formula.push({
                    operation: o.value.title,
                    category: o.type,
                    usedFormul: false,
                  });
                } else {
                  this.updateFormulData.formula.push({
                    operation: o.value,
                    category: o.type,
                    usedFormul: false,
                  });
                }
              });
            }
          }

          if (
            this.updateOperations[this.updateOperations.length - 1].type ==
            "Math Operators"
          ) {
            this.lastValueErrorToastMessage();
          } else if (this.updateOperations[0].type == "Math Operators") {
            this.firstValueErrorToastMessage();
          } else {
            if (
              this.updateFormulData.codeName != null ||
              this.updateFormulData.codeName != ""
            ) {
              if (this.preUpdateCodeName != this.updateFormulData.codeName) {
                this.$store
                  .dispatch("formul/checkFormulTitle", {
                    title: this.updateFormulData.codeName,
                  })
                  .then((res) => {
                    if (res.data.existing == 1) {
                      this.checkTitleToastMessage();
                    } else {
                      this.$store
                        .dispatch("formul/updateFormul", this.updateFormulData)
                        .then((res) => {
                          this.getFormuls();
                          this.resetFormul();
                          this.editToastMessage("Formul");
                          this.preUpdateCodeName = null;
                        })
                        .catch((error) => {
                          console.log("error update data ", error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("error get data ", error);
                  });
              } else {
                this.$store
                  .dispatch("formul/updateFormul", this.updateFormulData)
                  .then((res) => {
                    this.getFormuls();
                    this.resetFormul();
                    this.editToastMessage("Formul");
                    this.preUpdateCodeName = null;
                  })
                  .catch((error) => {
                    console.log("error update data ", error);
                  });
              }
            }
          }
        }
      });
      }
    },

    deleteFormulModal(id) {
      const self = this;
      if (id) {
        this.$swal({
          title: "Are you sure you want to delete the formul?",
          // text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("formul/deleteFormul", id)
              .then((res) => {
                self.getFormuls();
                self.$swal.fire(
                  "Deleted!",
                  "Formul has been deleted.",
                  "success"
                );
              })
              .catch((error) => {
                console.log("error update data ", error);
              });
          }
        });
      }
    },
    resetFormul() {
      this.updateFormulData = {
        id: 0,
        codeName: null,
        alias: null,
        type: null,
        category: null,
        isOutput: false,
        intrFormul: false,
        formula: [],
      };
      this.updateOperations = [];
      this.updateIfElseConditional = false
      this.updateConditionObj = {
        firstSelect: {
          type: null,
          value: null,
        },
        secondSelect: {
          type: null,
          value: null,
        },
        ifSelect: {
          type: null,
          value: null,
        },
        elseSelect: {
          type: null,
          value: null,
        },
        firstCondition: null,
        secondCondition: null,
        conditionOperation: null,
        category: "Conditional",
        if: null,
        else: null,
      };
      this.showUpdateFormul = false;
    },

    updateSelectedOperation(o, i) {
      if (o.type.title == "Conditional") {
        this.updateIfElseConditional = true;
      } else {
        this.updateOperations.forEach((operation, index) => {
          if (index == i) {
            operation.selected = 1;
            operation.type = o.type.title;
          }
        });
      }
    },
    updateSelectedPercentOperation(o, i) {
      this.updateOperations.forEach((operation, index) => {
        if (index == i) {
          operation.specialFormul = "*" + o.value + "/100";
          operation.value = "%" + o.value;
        }
      });
    },
    updateSelectedMathOperation(o, i) {
      this.updateOperations.forEach((operation, index) => {
        if (index == i) {
          operation.value = o.value;
        }
      });
    },
    updateSelectedFormulOperation(o, i) {
      // o.disabled = true;
      // o.usedFormul = true;
      // this.operations.splice(i, 1);
      // o.value.formul.forEach((f) => {
      //   f.disabled = true;
      //   this.operations.push(f);
      // });
    },

    updateSelectedInputOperation(o, i) {
      this.updateOperations.forEach((operation, index) => {
        if (index == i) {
          operation.value = o.value;
        }
      });
    },
    removeLastModalInput() {
      this.updateOperations.pop();
    },

    addNewModalInput() {
      this.$refs.updateMetricsRules.validate().then((success) => {
        if (success) {
          this.updateOperations.push({
            id: this.updateOperations.length + 1,
            selected: 0,
            type: "",
            value: null,
            disabled: false,
          });
        }
      });
    },
    updateDoubleclick(o, i) {
      this.updateOperations.forEach((operation, index) => {
        if (index == i) {
          operation.selected = 0;
          operation.value = null;
          operation.type = "";
        }
      });
    },
    addNewNextModalInput(o, i) {
      this.$refs.updateMetricsRules.validate().then((success) => {
        if (success) {
          this.updateOperations.splice(i + 1, 0, {
            id: this.updateOperations.length + 1,
            selected: 0,
            type: "",
            value: null,
            disabled: false,
          });
        }
      });
    },
    removetModalInput(o, i) {
      if (this.updateOperations.length > 1) {
        this.updateOperations.splice(i, 1);
      }
    },
    openUpdateInputSelectTypes(o, i) {
      if (this.updateOperations.length > 1) {
        if (i > 0) {
          if (this.updateOperations[i - 1].type == "Math Operators") {
            this.updateOption[1].disabled = false;
            this.updateOption[0].disabled = true;
            this.updateOption[2].disabled = true;
            this.updateOption[3].disabled = true;
            this.updateOption[4].disabled = true;
            this.updateOption[5].disabled = false;
            if (this.updateOperations.length == 3) {
              this.updateOption[4].disabled = false;
              this.updateOption[5].disabled = false;
            }
          } else if (this.updateOperations[i - 1].type == "Numeric") {
            this.updateOption[0].disabled = false;
            this.updateOption[1].disabled = true;
            this.updateOption[2].disabled = false;
            this.updateOption[3].disabled = false;
            this.updateOption[4].disabled = false;
            this.updateOption[5].disabled = false;
          } else if (this.updateOperations[i - 1].type == "Input") {
            this.updateOption[0].disabled = false;
            this.updateOption[1].disabled = true;
            this.updateOption[2].disabled = false;
            this.updateOption[3].disabled = false;
            this.updateOption[4].disabled = false;
            this.updateOption[5].disabled = false;
          } else if (this.updateOperations[i - 1].type == "Formula") {
            this.updateOption[0].disabled = false;
            this.updateOption[1].disabled = true;
            this.updateOption[2].disabled = false;
            this.updateOption[3].disabled = false;
            this.updateOption[4].disabled = false;
            this.updateOption[5].disabled = false;
          } else if (this.operations[i - 1].type == "Lookup Table") {
            this.updateOption[0].disabled = false;
            this.updateOption[1].disabled = false;
            this.updateOption[2].disabled = false;
            this.updateOption[3].disabled = false;
            this.updateOption[4].disabled = false;
            this.updateOption[5].disabled = false;
          }
        }
      } else if (this.updateOperations.length == 1) {
        this.updateOption[0].disabled = false;
        this.updateOption[1].disabled = false;
        this.updateOption[2].disabled = true;
        this.updateOption[3].disabled = true;
        this.updateOption[4].disabled = true;
        this.updateOption[5].disabled = true;
      }
    },
    updateCheckFormulTitle() {
      if (
        this.updateFormulData.codeName != null ||
        this.updateFormulData.codeName != ""
      ) {
        this.$store
          .dispatch("formul/checkFormulTitle", {
            title: this.updateFormulData.codeName,
          })
          .then((res) => {
            if (res.data.existing == 1) {
              this.checkTitleToastMessage();
            }
          })
          .catch((error) => {
            console.log("error get data ", error);
          });
      }
    },
    // update lookup operations

    updateSelectedLookupOperation(o, i) {
      o.columns = [];
      o.value.columns.forEach((c) => {
        o.columns.push({
          column: c,
          disabled: true,
        });
      });

      o.lookup = {
        table: o.value.table,
        selectC: null,
        resultC: null,
      };

      this.updateOperations.push(
        {
          id: this.updateOperations.length + 1,
          selected: 2,
          type: "Lookup Operation",
          value: null,
          disabled: false,
        },
        {
          id: this.updateOperations.length + 2,
          selected: 2,
          type: "Lookup Not Found",
          value: null,
          disabled: false,
        }
      );
      this.updateMinusBtnDisabled = true;
      o.selected = 2;
    },

    updateFilterSelectedColumn(o, i) {
      o.columns.forEach((c) => {
        if (o.lookup.resultC != null) {
          if (c.column == o.lookup.resultC) {
            c.disabled = false;
          } else {
            c.disabled = true;
          }
        }
      });
    },

    updateSelectedLookupColumnsOperation(o, i) {
      // console.log(o)
    },

    updateFilterSelectedResultColumn(o, i) {
      o.columns.forEach((c) => {
        if (o.lookup.selectC != null) {
          if (c.column == o.lookup.selectC) {
            c.disabled = false;
          } else {
            c.disabled = true;
          }
        }
      });
    },

    updateResultLookupColumnsOperation(o, i) {
      // console.log(o);
    },

    updateSelectedMiddleLookupOperation(o, i) {
      this.updateOperations.forEach((operation, index) => {
        if (index == i) {
          operation.selected = 2;
          operation.type = o.type.title;
          operation.value = null;
        }
      });
    },

    updateDoubleDefaultOprationClick(o, i) {
      this.updateOperations = [
        {
          id: this.updateOperations.length + 1,
          selected: 0,
          type: "",
          value: null,
          disabled: false,
        },
      ];
      this.updateMinusBtnDisabled = false;
    },

    //***************** update modal */

    //********** If Else Conditional section *************
    changeCalType() {
      this.ifElseConditional = false;
      this.operations = [
        {
          id: this.operations.length + 1,
          selected: 0,
          type: "",
          value: null,
          disabled: false,
        },
      ];
      this.conditionObj = {
        firstSelect: {
          type: null,
          value: null,
        },
        secondSelect: {
          type: null,
          value: null,
        },
        ifSelect: {
          type: null,
          value: null,
        },
        elseSelect: {
          type: null,
          value: null,
        },
        firstCondition: null,
        secondCondition: null,
        conditionOperation: null,
        category: "Conditional",
        if: null,
        else: null,
      };
    },
    // first & second contion section
    doubleConditionclick(o) {
      if (o == "first") {
        this.conditionObj.firstSelect = {
          type: null,
          value: null,
        };
        this.conditionObj.firstCondition = null;
      } else if (o == "second") {
        this.conditionObj.secondSelect = {
          type: null,
          value: null,
        };
        this.conditionObj.secondCondition = null;
      }
    },
    selectedConditionOperation(o) {},
    openConditionInputSelectTypes(o) {},
    selectedConditionNumericOperation(o, i) {
      if (i == "first") {
        this.conditionObj.firstCondition = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      } else if (i == "second") {
        this.conditionObj.secondCondition = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      }
    },
    selectedConditionFormulOperation(o, i) {
      if (i == "first") {
        this.conditionObj.firstCondition = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      } else if (i == "second") {
        this.conditionObj.secondCondition = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      }
    },

    // if & else section
    doubleIfElseConditionclick(o) {
      if (o == "if") {
        (this.conditionObj.ifSelect = {
          type: null,
          value: null,
        }),
          (this.conditionObj.if = null);
      } else if (o == "else") {
        (this.conditionObj.elseSelect = {
          type: null,
          value: null,
        }),
          (this.conditionObj.else = null);
      }
    },

    selectedIfElseConditionOperation(o) {},

    selectedIfElseConditionNumericOperation(o, t) {
      if (t == "if") {
        this.conditionObj.if = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      } else if (t == "else") {
        this.conditionObj.else = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      }
    },

    selectedIfElseConditionFormulOperation(o, t) {
      if (t == "if") {
        this.conditionObj.if = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      } else if (t == "else") {
        this.conditionObj.else = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      }
    },

    //update if else modal

    updateChangeCalType() {
      this.updateIfElseConditional = false;
      this.updateOperations = [
        {
          id: this.operations.length + 1,
          selected: 0,
          type: "",
          value: null,
          disabled: false,
        },
      ];
      this.updateConditionObj = {
        firstSelect: {
          type: null,
          value: null,
        },
        secondSelect: {
          type: null,
          value: null,
        },
        ifSelect: {
          type: null,
          value: null,
        },
        elseSelect: {
          type: null,
          value: null,
        },
        firstCondition: null,
        secondCondition: null,
        conditionOperation: null,
        category: "Conditional",
        if: null,
        else: null,
      };
      this.updateFormulData.category = null
    },

    updateSelectedConditionOperation(o) {},
    updateOpenConditionInputSelectTypes(o) {},

    updateSelectedConditionNumericOperation(o, i) {
      if (i == "first") {
        this.updateConditionObj.firstCondition = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      } else if (i == "second") {
        this.updateConditionObj.secondCondition = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      }
    },

    updateDoubleConditionclick(o) {
      if (o == "first") {
        this.updateConditionObj.firstSelect = {
          type: null,
          value: null,
        };
        this.updateConditionObj.firstCondition = null;
      } else if (o == "second") {
        this.updateConditionObj.secondSelect = {
          type: null,
          value: null,
        };
        this.updateConditionObj.secondCondition = null;
      }
    },

    updateSelectedConditionFormulOperation(o, i) {
      if (i == "first") {
        this.updateConditionObj.firstCondition = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      } else if (i == "second") {
        this.updateConditionObj.secondCondition = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      }
    },

    updateSelectedIfElseConditionOperation(o) {},

    updateSelectedIfElseConditionNumericOperation(o, t) {
      if (t == "if") {
        this.updateConditionObj.if = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      } else if (t == "else") {
        this.updateConditionObj.else = {
          category: "Numeric",
          operation: o.value,
          usedFormul: false,
        };
      }
    },

    // update if & else section
    updateDoubleIfElseConditionclick(o) {
      if (o == "if") {
        (this.updateConditionObj.ifSelect = {
          type: null,
          value: null,
        }),
          (this.updateConditionObj.if = null);
      } else if (o == "else") {
        (this.updateConditionObj.elseSelect = {
          type: null,
          value: null,
        }),
          (this.updateConditionObj.else = null);
      }
    },

    updateSelectedIfElseConditionFormulOperation(o, t) {
      if (t == "if") {
        this.updateConditionObj.if = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      } else if (t == "else") {
        this.updateConditionObj.else = {
          id: o.value.id,
          codeName: o.value.codeName,
          category: "Formula",
          usedFormul: true,
        };
      }
    },

    //********* end if else section */

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Added Successful",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Updated Successful",
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },
    lastValueErrorToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:
            "The last value cannot be a math operation. please check the formula",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
    firstValueErrorToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:
            "The first value cannot be a math operation. please check the formula",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
    checkTitleToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: "This title is used. Please check formula title",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    },
    checkFormulaToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "This formula cannot be formula only. Please add other inputs",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
  },
  watch: {
    conditionObj: {
      handler: function(co, before) {
        if (
          co.firstSelect.value != null &&
          co.secondSelect.value != null &&
          co.conditionOperation != null
        ) {
          this.visibleIfElse = true;
        } else {
          this.visibleIfElse = false;
        }
      },
      deep: true,
    },
    updateConditionObj: {
      handler: function(co, before) {
        if (
          co.firstSelect.value != null &&
          co.secondSelect.value != null &&
          co.conditionOperation != null
        ) {
          this.updateVisibleIfElse = true;
        } else {
          this.updateVisibleIfElse = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.input-dropdown .btn {
  padding: 0;
}
.checkbox-label .custom-control-label {
  font-size: 0.857rem !important;
}
.modal-height .modal-dialog .modal-content {
  height: 60vh !important;
}
.lookup-select-placeholder input::placeholder {
  font-size: 0.8rem;
}

.animate__animated {
  animation-fill-mode: none;
}
</style>
